import React, { Component, Fragment, useContext } from 'react';
import { Row, Col, Accordion, Card, AccordionContext, useAccordionToggle, Image, Table } from 'react-bootstrap';
import chart1 from '../assets/faq-pie-chart.svg';

function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <Card.Header
        className={ isCurrentEventKey ? 'active' : '' }
        onClick={decoratedOnClick}
      >
        {children}
      </Card.Header>
    );
  }

class Faqs extends Component{

    componentDidMount(){
        
    }


    render(){
        return(
            <Fragment>
                <Row id="Faqs">
                    <Col sm={{ span:8, offset: 4 }}>
                        <h4>Faqs</h4>
                        <h2>GENERAL QUESTIONS AND ANSWERS</h2>
                        <Accordion>
                            <Card>
                                <ContextAwareToggle eventKey="0">
                                What will I receive for my Aphria Shares under the arrangement?
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    Aphria Shareholders. Under the arrangement and subject to the terms of the plan of arrangement, each Aphria
shareholder will receive, for each Aphria Share held, 0.8381 of a Tilray Share. You will no longer own any Aphria
shares, but instead will own Tilray shares. As an example, if you owned 1,000 Aphria shares on the closing day of the
transaction, after the closing, you will own 838 Tilray Shares (since no fractional Tilray Shares are issued). <br /><br />
Tilray Stockholders. Under the arrangement, Tilray shareholders will retain their Tilray shares.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="1">
                                Why am I receiving 0.8381 of a Tilray Share for each of my Aphria shares?

                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                The agreed exchange Ratio is 0.8381. This means that on closing, each holder of Aphria shares will receive 0.08381 of
a Tilray Share for each Aphria Share. The exchange ratio was determined at the time of the announcement of the
transaction. At the time of the announcement of the transaction, Tilray had approximately 165,387,000 shares
outstanding and Aphria had approximately 321,953,000 outstanding, each on a fully diluted basis, it was expected
that the Exchange Ratio would result in Aphria shareholders owning approximately 62% of the outstanding shares of
Tilray, and existing Tilray shareholders owning approximately 38% of the outstanding shares of Tilray. Based on
changes between the announcement of the transaction and closing, the allocation is now 61% Aphria shareholders
and 39% Tilray shareholders.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="2">
                                How do I calculate the value of the Tilray Shares that I receive?
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    The value of the Tilray Shares that you will receive on closing of the transaction in exchange for your Aphria Shares
will depend on the trading price of Tilray Shares on the day the Transaction is completed. To help you value what you
will receive under the Transaction for your Aphria shares, see the example below: <br /><br />
                                                                    If you bought your Aphria Shares on the TSX, then:<br /><br />
                                Step 1: Calculate the number of Tilray Shares that you will receive<br /><br />
                                Calculate the number of Tilray shares that you will receive on closing by multiplying the number of Aphria Shares that
you own by 0.8381 (which is the Exchange Ratio).<br /><br />
                                Step 2: Calculate the value of the Tilray Shares that you will receive in US$<br /><br />
                                Multiply the answer from Step 1 by the current value of each Tilray Share.<br /><br />
                                Step 3: Calculate the value of the Tilray Shares that you will receive in C$<br /><br />
                                Multiply the answer from Step 2 by the US$ to C$ foreign exchange rate since the Tilray Shares currently trade in US$.
This will give you the value of the Tilray Shares to be received in C$.<br /><br />

                                As an example, assume that:<br />
                                · The value of each Tilray Share is US$20.00.<br />
                                · 1 US dollar = 1.2756 Canadian dollar<br />
                                · You own 1,000 Aphria Shares<br />
                                1,000 Aphria Shares X 0.8381 = 838 Tilray Shares<br />
                                838 Tilray Shares X US$20.00 per Tilray Share =US$16,760.00<br />
                                US$16,760.00 X 1.2756 = C$21,379.06 (or C$21.38 per Aphria Share that you own)<br />
                                If you bought your Aphria Shares on the Nasdaq, then:<br /><br />
                                Step 1: Calculate the number of Tilray Shares that you will receive<br /><br />
                                Calculate the number of Tilray Shares that you will receive on closing by multiplying the number of Aphria Shares that
you own by 0.8381 (which is the Exchange Ratio).<br /><br />
                                Step 2: Calculate the value of the Tilray Shares that you will receive in US$<br /><br />
                                Multiply the answer from Step 1 by the current value per Tilray Share<br /><br />
                                1,000 Aphria Shares X 0.8381 = 838 Tilray Shares<br /><br />
                                838 Tilray Shares X the current value per Tilray Share<br /><br />
                                Current and recent share price and trading data, and US$/C$ exchange rates, can be found at your preferred market
data sources, or the following:<br /><br />
                                     
                                    APHA trading on TSX: <a target="_blank" href="https://money.tmx.com/en/quote/APHA">https://money.tmx.com/en/quote/APHA</a> <br />
                                    APHA trading on Nasdaq: <a href="https://www.nasdaq.com/market-activity/stocks/apha" target="_blank">https://www.nasdaq.com/market-activity/stocks/apha</a><br />
                                    TLRY trading on Nasdaq: <a href="https://www.nasdaq.com/market-activity/stocks/tlry" target="_blank">https://www.nasdaq.com/market-activity/stocks/tlry</a><br />
                                    US$:C$ exchange rate <a href=":https://www.oanda.com/us-en/trading/instruments/usd-cad/" target="_blank">:https://www.oanda.com/us-en/trading/instruments/usd-cad/</a>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="3">
                                    Will I receive fractional Tilray Shares?
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                No. If the total number of Tilray Shares that you will be entitled would result in a fraction of a Tilray Share
being issuable, the number of Tilray Shares you will receive will be rounded down to the nearest whole
Tilray Share.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="4">
                                    What will happen to Aphria once the arrangement is completed?
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                   Once completed, Tilray will acquire all outstanding Aphria Shares and Aphria will become a wholly-owned
subsidiary of Tilray. Aphria Shares will be delisted from the TSX and NASDAQ.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="5">
                                    Are the Tilray Shares listed on a stock exchange?
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    Tilray Shares are currently listed on the Nasdaq under the symbol “TLRY” and trade in U.S. dollars. In
addition, Tilray currently expects to begin trading on the TSX on May 5, 2021, which will trade in Canadian
dollars. Consequently, following the closing, Tilray and Aphria shareholders are expected to be able to
trade their Tilray Shares on either exchange, in either currency.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        <Card>
                                <ContextAwareToggle eventKey="7">
                                    What are the Canadian federal income tax consequences of the arrangement?
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                Aphria Shareholders who are residents of Canada for purposes of the Tax Act should be aware that the
exchange of Aphria Shares for Tilray Shares under the arrangement will be a taxable transaction for
Canadian federal income tax purposes. Aphria Shareholders who are non-residents of Canada for purposes
of the Tax Act and that do not hold their Aphria Shares as “taxable Canadian property” will generally not be
subject to tax under the Tax Act on the exchange of their Aphria Shares for Tilray Shares under the
arrangement. <br /><br />
For a summary of certain of the principal Canadian federal income tax consequences of the arrangement
applicable to Aphria Shareholders, please refer to the section titled “Certain Canadian Federal Income Tax
Considerations” in the Circular. Such summary is not intended to be legal, business or tax advice. Aphria
Shareholders should consult their own tax advisors as to the tax consequences of the arrangement to them
with respect to their particular circumstances.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="9">
                                What are the U.S. federal income tax consequences of the arrangement?
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="9">
                                <Card.Body>
                                Aphria Shareholders should not recognize gain or loss as a result of the arrangement for U.S. tax purposes.
Each holder’s aggregate tax basis in Tilray Shares received should equal the aggregate tax basis of the
holder’s Aphria Shares surrendered in the arrangement, as applicable. Each holder’s holding period for
Tilray Shares received in the arrangement should include such holder’s holding period for the Aphria Shares
surrendered in the arrangement.<br /><br />
Holders who acquired different blocks of Aphria Shares with different holding periods and tax bases must
generally apply the foregoing rules separately to each identifiable block of Aphria Shares. Any such holder
should consult its tax advisor with regard to identifying the bases or holding periods of the particular shares
of Tilray Shares received in the arrangement.<br /><br />
However, since your tax circumstances may be unique, you should consult your tax advisor to determine
the tax consequences of the arrangement on you.<br /><br />
Please refer to the section titled “Certain U.S. Federal Income Tax Considerations” in the y Circular for a
more detailed discussion of the U.S. federal income tax treatment of the arrangement.

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <ContextAwareToggle eventKey="11">
                                What happens if I hold my Aphria Shares in an RESP, TFSA or RRSP account?
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="11">
                                <Card.Body>
                                    For Canadian resident shareholders that hold Aphria Shares in an RESP, TFSA, RRSP or other registered
account, no immediate Canadian tax will arise as a result of the arrangement, whether or not any gain is
realized on the disposition of Aphria Shares. Further, Tilray Shares will remain qualified investments for an
RESP, TFSA and RRSP or other registered accounts. <br /><br />
For a summary of certain of the principal Canadian federal income tax consequences of the arrangement
applicable to Aphria Shareholders, please refer to the section titled “Certain Canadian Federal Income Tax
Considerations” in the Circular. Such summary is not intended to be legal, business or tax advice. Aphria
Shareholders should consult their own tax advisors as to the tax consequences of the arrangement to them
with respect to their particular circumstances.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <ContextAwareToggle eventKey="12">
                                When will I receive the Consideration payable to me under the arrangement for my Aphria Shares?
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="12">
                                <Card.Body>
                                YYou will receive the Consideration due to you under the arrangement as soon as practicable after the
arrangement becomes effective and your Letter of Transmittal and Aphria Share certificate(s) or DRS
Advice(s) and all other required documents are properly completed and received by the Depositary.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <ContextAwareToggle eventKey="36">
                                Who can help answer my questions?
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="36">
                                <Card.Body>
                                For further information, please feel free to contact us. <a href="https://www.tilray.com/home/#contact" target="_blank">https://www.tilray.com/home/#contact</a>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            
                        </Accordion>

                    </Col>
                </Row>
            </Fragment>
          );
    }
}

export default Faqs;