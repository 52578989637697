import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Transaction } from './component/';
// import ReactGA from 'react-ga';

class TransactionPage extends Component{
    componentDidMount(){
        // var ga = ReactGA.ga();
        // if (!ga) ReactGA.initialize('G-3NM4VF3BZ0');
        // ReactGA.pageview('/Transaction');
      }
    render(){
        return (
            <Fragment>
            <Transaction />
            </Fragment>
        );
    }
}

export default TransactionPage;
