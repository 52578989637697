import React, { Component, Fragment } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import ceo1 from '../assets/image-simon.jpg';
import ceo2 from '../assets/image-kennedy.jpg';

class AphriaTilray extends Component{

    componentDidMount(){
        
    }


    render(){
        return(
            <Fragment>
                <Row id="AphriaTilray">
                    <Col sm={{ span:8, offset: 4 }}>
                        <h4>Tilray, Inc.</h4>
                        <h2>Leadership</h2>
                        <ul>
                            <li>Irwin D. Simon, Chairman and Chief Executive Office</li>
                            <li>Carl Merton, Chief Financial Officer</li>
                            <li>Denise Faltischek, Head of International and Chief Strategy Officer</li>
                            <li>Jim Meiers, President, Canada</li>
                            <li>Jared Simon, President, Manitoba Harvest and Tilray Wellness</li>
                            <li>Rita Seguin, Chief Human Resources Officer</li>
                            <li>Dara Redler, Interim Chief Legal Officer and Corporate Secretary</li>
                            <li>Berrin Noorata, Chief Corporate Affairs Officer</li>
                            <li>Lloyd Brathwaite, Chief Information Officer</li>
                            <li>Freddy Bensch, Chief Executive Officer, SweetWater</li>
                        </ul>
                    </Col>
                    <Col sm={{ span:8, offset: 4 }}>
                        <h2>Board of Directors</h2>
                        <ul>
                            <li>Irwin D. Simon, Chairman</li>
                            <li>Renah Persofsky, ICD.D, Vice-Chair (Lead Director) and Chair of the Nominating and Governance Committee, Independent Director</li>
                            <li>Jodi Butts, Nominating & Governance Committee Member, Independent Director</li>
                            <li>David Clanachan, Newly Appointed Independent Director</li>
                            <li>John M. Herhalt Chair of the Audit Committee, Independent Director</li>
                            <li>David Hopkinson, Nominating and Governance Committee & Compensation Committee Member, Independent Director</li>
                            <li>Brendan Kennedy, Current Director and Former CEO, Tilray</li>
                            <li>Tom Looney, Audit Committee & Compensation Committee Member, Independent Director</li>
                            <li>Walter Robb, Chair of the Compensation Committee & Audit Committee Member, Independent Director</li>
                        </ul>
                    </Col>
                </Row>
            </Fragment>
          );
    }
}

export default AphriaTilray;