import React, { Component, Fragment } from 'react';
import { Col, Nav } from 'react-bootstrap';
import Affix from '@uiw/react-affix';
import { withRouter } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

function NavListChildren({ children, isnews, callback }) {
    return (
        <Fragment>
        <li><Nav.Link as={NavHashLink} smooth to="/#Welcome" eventKey="0" activeClassName="active" onSelect={function(){}}>Welcome</Nav.Link></li>
        <li><Nav.Link as={NavHashLink} smooth to="/#AphriaTilray" eventKey="1" activeClassName="active">Leadership</Nav.Link></li>
        {/* <li><Nav.Link as={NavHashLink} smooth to="/Transaction" eventKey="2" activeClassName="active">Transaction</Nav.Link></li> */}
        {/* <li><Nav.Link as={NavHashLink} smooth to="/#Investors" eventKey="3" activeClassName="active">Investors</Nav.Link></li> */}
        <li><Nav.Link as={NavHashLink} smooth to="/#Faqs" eventKey="4" activeClassName="active">FAQS</Nav.Link></li>
        {/* <li><Nav.Link as={NavHashLink} smooth to={isnews?"/LatestNews":"/#LatestNews"} eventKey="5" activeClassName="active">Latest News</Nav.Link></li> */}
        <li><Nav.Link as={NavHashLink} smooth to="/#Contact" eventKey="6" activeClassName="active">Contact</Nav.Link></li>
        {/* <li><Nav.Link as={NavHashLink} smooth to="/#ImportantInfo" eventKey="7" activeClassName="active">Important Information</Nav.Link></li> */}
        </Fragment>
    );
}

class NavList extends Component{

    componentDidMount(){
    }


    render(){
        const path = this.props.location.pathname;
        const ishome = path=='/';
        const isnews = path=='/LatestNews';
        const isnav = this.props.context=="navbar";
        
        return(
            <Fragment>
                { isnav 
                ?
                <Nav className="d-sm-none">
                    <NavListChildren isnews={isnews} />
                </Nav>
                :
                <Col sm="4" className="desktop-nav">
                    <h1>
                        Leading the global cannabis industry
                    </h1>
                    <Affix offsetTop={10} offsetBottom={"unset"}>
                        <ul className="d-none d-sm-block">
                            <NavListChildren isnews={isnews} />
                        </ul>
                    </Affix>
                </Col>
                }
            </Fragment>
          );
    }
}

export default withRouter(NavList);