import React, { Component, Fragment } from 'react';
import { Navbar, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import tlogo from '../assets/tilray-logo.png';

import NavList from './NavList';

class Header extends Component{

    componentDidMount(){
        
    }

    render(){
        return(
            <Fragment>
                <div className="toast-banner">
                    <p className="bluebox small">
                        For more information, please visit <a href="https://www.tilray.com/" target="_blank" alt="Tilray.com">Tilray.com</a>
                    </p> 
                </div>    

                <Navbar className="row" expand="sm" collapseOnSelect>
                    <Navbar.Brand href="/">
                        <img src={tlogo} className="App-logo" alt="Tilray" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <hr className="d-sm-none" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <NavList context="navbar" />
                    </Navbar.Collapse>
                </Navbar>
                <hr className="post-nav d-none d-sm-block" />
                
            </Fragment>
          );
    }
}

export default withRouter(Header);