import React, { Component, Fragment } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import photo from '../assets/image-contact.jpg';

class Contact extends Component{

    componentDidMount(){
        
    }


    render(){
        return(
            <Fragment>
                <Row id="Contact">
                    <Col sm={{ span:8, offset: 4 }}>
                        <h4>Contact</h4>
                        <h2>If you have questions or need more information about the proposed transaction, please contact:</h2>
                        <Row>
                            <Col sm="6" className="contact_wrapper">
                                <h4>Aphria Contacts:</h4>
                                <p>Investors</p>
                                <p>Investor Relations<br/>
                                <a href="mailto:investors@tilray.com">investors@tilray.com</a><br/>&nbsp;</p>
                            </Col>
                            <Col sm="6" className="contact_wrapper">
                                <h4>Tilray Contacts:</h4>
                                <p>Investors</p>
                                <p>Raphael Gross<br/>
                                <a href="mailto:Raphael.Gross@icrinc.com">Raphael.Gross@icrinc.com</a><br/>
                                203-682-8253</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fragment>
          );
    }
}

export default Contact;