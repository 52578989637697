import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import NavList from './NavList';
import logos from './logos';
import MailchimpSubscribe from "react-mailchimp-subscribe";
 
import pdf from '../assets/press-release.pdf';

class Welcome extends Component{

    componentDidMount(){
        
    }


    render(){
        const url = "//aphria.us8.list-manage.com/subscribe/post?u=66ea8c95b084a307478669dc1&id=463a14387b";

        return(
            <Fragment>
                <Row id="Welcome">
                    <NavList />

                    <Col sm={{ span:8 }}>
                        <div className="header-video">
                            <iframe src="https://player.vimeo.com/video/544014811?color=05dddd&title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        </div>

                        <div className="press-release">
                            <p className="large">Tilray &amp; Aphria Announce Closing of Transaction that Creates the “New” Tilray — A Global Cannabis Leader</p>
                            <a href={pdf} target="_blank" alt="Read full press release">Read Full Press Release →</a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span:8, offset: 4 }}>
                        <div className="logo_list">
                            <h4>Great Brands Grow Here</h4>
                            <ul>
                                { logos.aphria.map( ({id,src,title,url}) => <li key={id}><a href={url} target="_blank"><img src={src} title={title} alt={title} /></a></li> )}
                            </ul>
                            <ul>
                                { logos.tilray.map( ({id,src,title,url}) => <li key={id}><a href={url} target="_blank"><img src={src} title={title} alt={title} /></a></li> )}
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span:8, offset: 4 }}>
                        <div className="mc-form-wrapper">
                            <p className="large">Sign up to receive updates about the transaction</p>
                            <MailchimpSubscribe url={url} />
                            {/* <form className="signup" onSubmit={this.handleSubmit}>
                                <input type="email" id="email" name="email" placeholder="Email address" onChange={this.handleInputChange} value={email} />
                                <input type="submit" id="submit" value="SIGN UP" />
                            </form> */}

                            <small>This form collects your name and email so that we can add you to our distribution list for updates from Aphria Inc. and Tilray, Inc. Check out our <a href="https://aphriainc.com/general-privacy-policy/" target="_blank">privacy policies</a> for the full story on how we protect and manage your submitted data.<br/>By clicking sign up, I consent to having Tilray, Inc. to collect my name and email.</small>
                        </div>
                    </Col>
                </Row>
                              
            </Fragment>
          );
    }
}

export default Welcome;