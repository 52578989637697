import React, { Component, Fragment, useState, useContext } from 'react';
import { Row, Col, Accordion, Card, AccordionContext, useAccordionToggle, Image, Modal } from 'react-bootstrap';
import NavList from './NavList';
// import ReactGA from 'react-ga';

import slide1 from '../assets/global-cannabis-leader.png';
import slide2 from '../assets/largest-global-company-revenue.png';
import slide3 from '../assets/leading-adult-use.png';
import slide4 from '../assets/meaningful-cost-synergies.png';
import slide5 from '../assets/farm-to-pharmacy.png';

function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <Card.Header
        className={ isCurrentEventKey ? 'active' : '' }
        onClick={decoratedOnClick}
      >
        {children}
      </Card.Header>
    );
}

class Transaction extends Component{

    constructor(){
        super();
        this.state = {
            show: false,
            slide: '',
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    handleModal( slide ){
        if (!slide) {
            this.setState( { show: false } );
        } else {
            // ReactGA.modalview(slide);
            window.gtag('event', 'modal', {'value':slide});
            this.setState( { show: true, slide: slide });
        }
        
    }

    render(){
        

        return(
            <Fragment>
                <Row id="Transaction">
                    <NavList />
                    <Col sm={{ span:8, offset: 2 }}>
                        <h4>Transaction Overview</h4>

                        <Accordion>
                            <Card>
                                <ContextAwareToggle eventKey="0">
                                Additional Information and Where to Find It
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                <p>
                                    In connection with the proposed transaction, Aphria will file a management information circular, and Tilray has filed a
                                    proxy statement on Schedule 14A (which is subject to completion and may be amended) containing important
                                    information about the proposed transaction and related matters. Additionally, Aphria and Tilray will file other relevant
                                    materials in connection with the proposed transaction with the applicable securities regulatory authorities. Investors and
                                    security holders of Aphria and Tilray are urged to carefully read the entire management information circular and proxy
                                    statement (including any amendments or supplements to such documents), respectively, before making any voting
                                    decision with respect to the proposed transaction because they contain important information about the proposed
                                    transaction and the parties to the transaction. The Aphria management information circular and the Tilray proxy
                                    statement will be mailed to the Aphria and Tilray shareholders, respectively, as well as be accessible on the SEDAR and
                                    EDGAR profiles of the respective companies.</p>
                                    <p>Investors and security holders of Tilray can obtain a free copy of the proxy statement, as well as other relevant filings
                                    containing information about Tilray and the proposed transaction, including materials incorporated by reference into the
                                    proxy statement, without charge, at the SEC’s website (<a href="http://www.sec.gov" target="_blank">www.sec.gov</a>) or from Tilray by contacting Tilray’s Investor
                                    Relations at (203) 682-8253, by email at Raphael.Gross@icrinc.com, or by going to Tilray’s Investor Relations page on its
                                    website at <a href="https://ir.tilray.com/investor-relations" target="_blank">https://ir.tilray.com/investor-relations</a> and clicking on the link titled “Financials.”</p>
                                    <p>Investors and security holders of Aphria will be able to obtain a free copy of the information circular, as well as other
                                    relevant filings containing information about Aphria and the proposed transaction, including materials incorporated by
                                    reference into the information circular, without charge, on SEDAR at <a href="http://www.sedar.com" target="_blank">www.sedar.com</a> or from Aphria by contacting
                                    Aphria’s investor relations at <a href="mailto:investors@aphria.com">investors@aphria.com</a>.
                                </p>
<h4>Participants in the Solicitation</h4>
<small>
    Tilray and Aphria and certain of their respective directors, executive officers and employees may be deemed to be
    participants in the solicitation of Tilray and Aphria proxies in respect of the proposed transaction. Information regarding
    the persons who may, under applicable securities laws, be deemed participants in the solicitation of proxies of Tilray
    stockholders and Aphria shareholders in connection with the proposed transaction, and other information regarding the
    solicitation of proxies by Tilray and Aphria are set forth in the Tilray proxy statement for the proposed transaction and
    will be described in the information circular of Aphria. Copies of these documents may be obtained, free of charge, from
    the SEC, on SEDAR or from Tilray or Aphria as described in the preceding paragraph.
</small>

<h4>CAUTIONARY NOTE REGARDING FORWARD-LOOKING STATEMENTS:</h4>
<small>
    This website contains forward-looking information or forward-looking statements (together, “forward-looking
    statements”) under Canadian securities laws and within the meaning of Section 27A of the Securities Act of 1933, as
    amended, and Section 21E of the Securities Exchange Act of 1934, as amended, which are intended to be covered by the
    safe harbor created by such sections and other applicable laws. The forward-looking statements on this website are
    expressly qualified by this cautionary statement. Forward-looking statements are provided for the purpose of presenting
    information about management’s current expectations and plans relating to the future, and readers are cautioned that
    such statements may not be appropriate for other purposes. Any information or statements that are contained in this
    website that are not statements of historical fact may be deemed to be forward-looking statements, including, but not
    limited to, statements in this website with regards to and not limited to: (i) statements relating to Aphria’s and Tilray’s
    strategic business combination and the expected timing and closing of the transaction including, receipt of required
    regulatory approvals, shareholder approvals, court approvals and satisfaction of other closing customary conditions; (ii)
    estimates of pro-forma financial information of the combined company, including in respect of expected revenues,
    production of cannabis, adjusted EBITDA, pro-forma capitalization and pro-forma key financials; (iii) estimates of future
    cost reductions, synergies, including expected pre-tax synergies in an amount of more than US$78 million (C$100 million),
    savings and efficiencies; (iv) statements that the combined company anticipates having a scalable medical and adult-use
    cannabis platform expected to strengthen the leadership position in Canada internationally and, eventually in the United
    States; (v) statements that the combined company is expected to offer a diversified and branded product offering and
    distribution footprint, world-class cultivation, processing and manufacturing facilities, (vi) the number of Tilray shares
    issuable to Aphria shareholders and the expected ownership percentages of Tilray and Aphria shareholders after the
    closing of the transaction and (vii) statements in respect of other strategic and financial benefits in connection with the
    business combination. This website uses words such as “forecast”, “future”, “should”, “could”, “enable”, “potential”,
    “contemplate”, “believe”, “anticipate”, “estimate”, “plan”, “expect”, “intend”, “may”, “project”, “will”, “would” and the
    negative of these terms or similar expressions to identify forward-looking statements, although not all forward-looking
    statements contain these identifying words. Certain material factors or assumptions were used in drawing the conclusions contained in the forward-looking statements throughout this website, including the ability of the parties to receive, in a
    timely manner and on satisfactory terms, the necessary shareholder, regulatory and court approvals for the business
    combination, the ability of the parties to satisfy, in a timely manner, the conditions to closing of the business combination
    and other expectations and assumptions concerning the arrangement. Forward-looking statements reflect current beliefs
    of management of Aphria and Tilray with respect to future events and are based on information currently available to
    each respective management team including the assumptions, estimates, analysis and opinions of management of Aphria
    and Tilray considering their experience, perception of trends, current conditions and expected developments as well as
    other factors that each respective management believes to be relevant as at the date such statements are made. Forward-
    looking statements involve significant known and unknown risks and uncertainties. Many factors could cause actual
    results, performance or achievement to be materially different from any future forward-looking statements. Factors that
    may cause such differences include, but are not limited to, the inherent uncertainty associated with financial or other
    projections or outlooks, Aphria and Tilray’s future financial and operating performance, the commercial and business
    plans of Aphria and Tilray and the other risks assumptions and expectations described in the joint proxy circular to be
    mailed to shareholders in respect of the transaction. There can be no assurance as to when conditions in respect of the
    transaction will be satisfied or waived, if at all, or that other events will not intervene to delay or result in the failure to
    complete the transaction. There is also a risk that some or all of the expected benefits of the transaction may fail to
    materialize or may not occur within the time periods anticipated by Aphria and Tilray. The challenge of coordinating
    previously independent businesses makes evaluating the business and future financial prospects of the combined company
    following the transaction difficult. Readers are cautioned that the foregoing list of factors is not exhaustive. Other risks
    and uncertainties not presently known to Aphria and Tilray or that Aphria and Tilray presently believe are not material
    could also cause actual results or events to differ materially from those expressed in the forward-looking statements
    contained herein. Aphria and Tilray disclaim any obligation to update or revise any forward-looking statements, whether
    as a result of new information, future events or otherwise, except as required by law.
</small>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>    

                        <h2>Structure</h2>
                        <ul>
                            <li>Share-for-share combination, which takes the form of a reverse acquisition of Tilray executed by way of a Plan of Arrangement under the Business
                            Corporations Act (Ontario)</li>
                            <li>Aphria shareholders to receive 0.8381 shares of a Tilray share for each Aphria
                            share they own -- Tilray stockholders to continue to hold their shares</li>
                            <li>Assuming no change in the issued and outstanding share numbers of Tilray and Aphria, expected pro forma ownership of approximately 62% for Aphria shareholders and 38% for Tilray stockholders, on a fully diluted basis, at closing</li>
                        </ul>
                        <h2>Board of Directors</h2>
                        <ul>
                            <li>Irwin D. Simon: Chairman &amp; CEO</li>
                            <li>The board of directors will consist of 9 members:
                            − 7 current Aphria directors, including Mr. Simon
                            − 2 current Tilray directors, including Brendan Kennedy with 1
                            additional director to be designated by the Tilray board</li>
                        </ul>
                        <h2>Management</h2>
                        <ul>
                            <li>Irwin D. Simon: Chairman and Chief Executive Officer</li>
                        </ul>
                        <h2>Name / Ticker / Location</h2>
                        <ul>
                            <li>Name: Tilray, Inc. | Ticker: Nasdaq: TLRY</li>
                            <li>Principal offices in United States (New York and Seattle), Canada
                            (Toronto, Leamington and Vancouver Island), Portugal and Germany</li>
                        </ul>
                        <h2>Domicile</h2>
                        <ul>
                            <li>The combined company will operate under Tilray, Inc., a Delaware
                            corporation</li>
                        </ul>   
                        <h2>Conditions / Timing</h2>
                        <ul>
                            <li>Subject to customary closing conditions, including regulatory and
                            shareholders' approvals, as well as court approval of the Plan of Arrangement</li>
                            <li>Expected to close as soon as practicable following the satisfaction of the conditions to closing set out in the Arrangement Agreement</li>
                        </ul>
                        <h2>A Global Cannabis Leader</h2>
                        <Row>
                            <Col sm="6">
                                <ul>
                                    <li>Combines two complementary businesses to create a leading cannabis-focused CPG
company, and the largest globally by revenue</li>
                                    <li>Expected to strengthen leadership position in Canada, with complete portfolio of branded
cannabis 2.0 product offerings and carefully curated brands</li>
                                    <li>Bolsters scale and capabilities of leading international medical cannabis business, with
end-to-end GMP supply chain</li>
                                    </ul>
                            </Col>
                            <Col sm="6">
                                <ul>
                                    <li>Builds multi-pronged U.S. consumer packaged goods platform to be leveraged for
cannabis products when regulations allow</li>
                                    <li>Creates financial flexibility with a strong balance sheet and a best-in-class management
team and board, highlighted by consumer-packaged goods and cannabis operating
experience</li>
                                    </ul>
                            </Col>
                        </Row>
                                
                        <h2>The Combined Company in Numbers</h2>
                        <Row className="transaction-grid">
                            <Col sm="6">
                                <Image src={slide2} alt="Largest Global Cannabis Company by Revenue" fluid />
                                <a onClick={()=>this.handleModal(slide2)}>View full image</a>
                            </Col>
                            <Col sm="6">
                                <Image src={slide3} alt="Leading Adult-Use Position in Canada" fluid />
                                <a onClick={()=>this.handleModal(slide3)}>View full image</a>
                            </Col>
                            <Col sm="6">
                                <Image src={slide4} alt="Meaningful Cost Synergies with Additional Upside" fluid />
                                <a onClick={()=>this.handleModal(slide4)}>View full image</a>
                            </Col>
                            <Col sm="6">
                                <Image src={slide5} alt="Farm to Pharmacy in EMEA" fluid />
                                <a onClick={()=>this.handleModal(slide5)}>View full image</a>
                            </Col>
                        </Row>
                        <a href="/#Investors">Continue to Investor Information</a>
                    </Col>
                </Row>
                <Modal show={this.state.show} onHide={()=>this.handleModal(false)} size="lg" centered>
                    <Modal.Body>
                        <Image src={this.state.slide} alt="Largest Global Cannabis Company by Revenue" fluid />
                    </Modal.Body>
                </Modal>
            </Fragment>
          );
    }
}

export default Transaction;