import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';



class Investors extends Component{

    state = {
        investors : [],
        ready: false
    }

    componentDidMount(){
        //axios.get('https://staging.aphriatilraytogether.com/cms/wp-json/wp/v2/investor/').then(res => {
            //const investors = res.data;

            // investors.map((investor, index) => {
            //     const d                 = new Date(),
            //           month             = d.toLocaleDateString('default', {month: "long"}),
            //           day               = d.getDate(),
            //           year              = d.getFullYear(),
            //           formatted_date    = `${month} ${day}, ${year}`;

            //           investors[index].date = formatted_date;
            // })

            //this.setState({
            //    investors
            //})
        //})    
    }


    render(){

        return(
            <Fragment>
                <Row id="Investors">
                    <Col sm={{ span:8, offset: 4 }}>
                        <a href="https://ir.tilray.com/" target="_blank" alt="Tilray IR"><h4>Investors</h4></a>
                        <ul>
                            <li>Name: Tilray, Inc. | Ticker: Nasdaq: TLRY</li>
                            <li>Principal offices in United States (New York and Seattle), Canada (Toronto, Leamington and Vancouver Island), Portugal and Germany</li>
                        </ul>
                    </Col>
                </Row>
                
            </Fragment>
          );
    }
}

export default Investors;