import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';

import logos from './logos';

class Footer extends Component{

    componentDidMount(){
        
    }


    render(){
        return(
            <Fragment>
                <Row id="Footer">
                    <Col className="footer-wrapper">
                        <div>
                            <h4>Aphria Inc.</h4>
                            <a href="https://twitter.com/AphriaInc" target="_blank"><img src={logos.social[0].src} title={'Aphria Inc. '+logos.social[0].title} alt={logos.social[0].title} /></a>
                            <a href="https://www.linkedin.com/company/aphriainc/" target="_blank"><img src={logos.social[1].src} title={'Aphria Inc. '+logos.social[1].title} alt={'Aphria '+logos.social[1].title} /></a>
                        </div>
                        <div>
                            <h4>Tilray, Inc.</h4>
                            <a href="https://twitter.com/tilray" target="_blank"><img src={logos.social[0].src} title={'Tilray, Inc. '+logos.social[0].title} alt={logos.social[0].title} /></a>
                            <a href="https://www.linkedin.com/company/tilray/" target="_blank"><img src={logos.social[1].src} title={'Tilray, Inc. '+logos.social[1].title} alt={'Tilray '+logos.social[1].title} /></a>
                        </div>
                        <a href="https://aphriainc.com/general-privacy-policy/" target="_blank">Privacy Policy</a>
                        <a href="https://aphriainc.com/terms-of-use/" target="_blank">Terms of Use</a>
                    </Col>
                </Row>
            </Fragment>
          );
    }
}

export default Footer;