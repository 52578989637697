import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Welcome, AphriaTilray, Transaction, Investors, Faqs, LatestNews, Contact, ImportantInfo, Footer } from './component/';
// import ReactGA from 'react-ga';

// import dt_herovid from './assets/Aphria-Tilray-Sitevideo-Desktop-1280x720.mp4';
// import mb_herovid from './assets/Aphria-Tilray-Sitevideo-Mobile-720x720.mp4';

class Home extends Component{
  componentDidMount(){
    // var ga = ReactGA.ga();
    // if (!ga) ReactGA.initialize('G-3NM4VF3BZ0');
    // ReactGA.pageview('/');
  }
  render(){
    return (
      <Fragment>

        <Welcome />
        <AphriaTilray />
        {/* <Transaction /> */}
        <Investors />
        <Faqs />
        {/* <LatestNews /> */}
        <Contact />
        {/*<ImportantInfo /> */}
      </Fragment>
    );
  }
}

export default Home;
