import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NavList from './NavList';
import axios from 'axios';

class LatestNews extends Component{

    state = {
        news_posts: [],
        isLoaded: false
    }

    componentDidMount(){
        axios.get('https://staging.aphriatilraytogether.com/cms/wp-json/wp/v2/posts/').then((res) => {
            let news_posts = res.data;

            news_posts.map((news, index) => {
                const d = new Date(news.date);
                const month = d.toLocaleDateString('default', {month: 'short'});
                const day = d.getDate();
                const year = d.getFullYear();
                const formatted_date = `${month} ${day}, ${year}`;

                news_posts[index].date = formatted_date;
            })

            this.setState({
                news_posts,
                isLoaded: true
            })
        })
    }


    render(){
        const path = this.props.location.pathname;
        const ishome = path=='/';

        const allNewsItems = this.state.news_posts.length;
        const itemToLoad = ishome ? 4 : allNewsItems;

        const { news_posts, isLoaded} = this.state;

        //const{ date, title } = news;
        return(
            <Fragment>
                <Row id="LatestNews">
                    { !ishome && <NavList /> }
                    <Col sm={{ span:8, offset: ishome ? 4 : 2 }}>
                        <h4>Latest News</h4>
                        <h2>Read what's being said about the proposed Transaction</h2>
                        <Row>

                            {news_posts.slice(0, itemToLoad).map((news, index) => (
                                <Fragment>
                                <Col sm="6" className="news_wrapper" key={news.id}>
                                    <small className="news__date">{news.date}</small>
                                    <h3 dangerouslySetInnerHTML={{__html: news.title.rendered}}></h3>
                                    <span dangerouslySetInnerHTML={{__html: news.content.rendered}}></span>
                                    <small>{news.acf.source}</small>
                                    <a href={news.acf.external_link} target="_blank">Link to the article</a>
                                </Col>
                                <span className={index%2 !== 0 ? 'news-separator' : ''}></span>
                                </Fragment>
                            ))}

                        </Row>
                        {ishome && <a href="/LatestNews">Read all articles</a>}
                    </Col>
                </Row>
            </Fragment>
          );
    }
}

export default withRouter(LatestNews);