import './App.scss';

import { Container, Row, Col } from 'react-bootstrap';
import { Header, Footer } from './component/';

import Home from './Home';
import TransactionPage from './Transaction';
import LatestNewsPage from './LatestNews';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <Container className="App">
      <Router>
        <Header />
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Transaction" component={TransactionPage} />
            <Route exact path="/LatestNews" component={LatestNewsPage} />
        </Switch>
        <Footer />
      </Router>
    </Container>
  );
}

export default App;
