import aphrialogo1 from '../assets/aphria_inc_logo.png';
import aphrialogo2 from '../assets/Bingo_Logo_grayscale 2.png';
import aphrialogo3 from '../assets/Ptitepof_logo_grayscale 1.png';
import aphrialogo4 from '../assets/GoodSupply_Stacked_grayscale 1.png';
import aphrialogo5 from '../assets/Solei_logo_grayscale 1.png';
import aphrialogo6 from '../assets/RIFF_Boxed_grayscale 1.png';
import aphrialogo7 from '../assets/BCC_Logo_grayscale 1.png';
import aphrialogo8 from '../assets/SWB-Logo-grayscale 1.png';
import aphrialogo9 from '../assets/420 Strain Bones.png';
import aphrialogo10 from '../assets/420-EPA.png';

import tilraylogo1 from '../assets/Tilray-grayscale.png';
import tilraylogo2 from '../assets/TheBatch_logo_grasycale 1.png';
import tilraylogo3 from '../assets/Canaca_Logo_grayscale 1.png';
import tilraylogo4 from '../assets/Chowie Wowie_Logo_grayscale 1.png';
import tilraylogo5 from '../assets/Dubon_Logo_grayscale 1.png';
import tilraylogo6 from '../assets/Grail_Logo_grayscale 1.png';
import tilraylogo7 from '../assets/marleynatrual_logo_grayscale 1.png';
import tilraylogo8 from '../assets/Everie_logo_grayscale 1.png';
import tilraylogo9 from '../assets/HighPark.png';
import tilraylogo10 from '../assets/ManitobaHarvest_grey.png';
import tilraylogo11 from '../assets/Pollen_grey.png';

import social1 from '../assets/icon-twitter.svg';
import social2 from '../assets/icon-linkedin.svg';

// images.js
const logos = { aphria: [
    { id: 1, src: aphrialogo1, title: 'aphria inc.', url:'https://aphriainc.com/' },
    { id: 2, src: aphrialogo2, title: 'Bingo', url:'https://www.bingocannabis.ca/age-gate' },
    { id: 3, src: aphrialogo3, title: 'Ptite Pof' },
    { id: 4, src: aphrialogo4, title: 'Good Supply', url:'https://goodsupplycannabis.com/en/' },
    { id: 5, src: aphrialogo5, title: 'Solei', url:'https://solei.ca/en/' },
    { id: 6, src: aphrialogo6, title: 'RIFF', url:'https://whatisriff.ca/en/' },
    { id: 7, src: aphrialogo7, title: 'Broken Coast', url:'https://www.brokencoast.com/' },
    { id: 8, src: aphrialogo8, title: 'Sweet Water', url:'https://www.sweetwaterbrew.com/' },
    { id: 9, src: aphrialogo9, title: '420 Strain Bones', url:'https://www.sweetwaterbrew.com/' },
    { id: 10, src: aphrialogo10, title: '420 EPA', url:'https://www.sweetwaterbrew.com/' },
  ],
  tilray: [
    { id: 1, src: tilraylogo1, title: 'Tilray', url:'https://www.tilray.com/' },
    { id: 2, src: tilraylogo9, title: 'High Park', url:'https://www.highparkcompany.com/' },
    { id: 3, src: tilraylogo10, title: 'Manitoba Harvest', url:'https://manitobaharvest.com/' },
    { id: 4, src: tilraylogo8, title: 'Everie', url:'https://everie.ca/' },
    { id: 5, src: tilraylogo4, title: 'Chowie Wowie', url:'https://chowiewowie.ca/' },
    { id: 6, src: tilraylogo7, title: 'Marley Natural', url:'https://www.marleynatural.com/' },
    { id: 7, src: tilraylogo2, title: 'The Batch', url:'https://thebatchcannabis.ca/' },
    { id: 8, src: tilraylogo3, title: 'Canaca', url:'https://www.canacacannabis.ca/' },
    { id: 9, src: tilraylogo5, title: 'DuBon' },
    { id: 10, src: tilraylogo6, title: 'Grail', url:'https://www.grailcannabis.ca/' },
    { id: 11, src: tilraylogo11, title: 'Pollen', url:'https://withpollen.com/' },
  ],
  social: [
    { id: 1, src: social1, title: 'Twitter' },
    { id: 2, src: social2, title: 'LinkedIn' },
  ]
};
  
export default logos;